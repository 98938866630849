@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");
@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 480px) {
  body {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 480px) {
  body {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}
