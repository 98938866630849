.btn-primary {
    background-color: green; /* Change background color to green */
    color: white; /* Text color */
    padding: 10px 20px; /* Adjust padding as needed */
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .right-align {
    position: absolute;
    /* top: 80px; Adjust top position as needed */
    right: 45px; /* Adjust right position as needed */
  }
  