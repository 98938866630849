.clear {
    clear: both;
}

.checkBox {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #c93a0e;
}


.checkBox div {
    width: 40px;
    height: 40px;
    background-color: #c93a0e;
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
}

.checkBox input[type=checkbox]:checked+div {
    left: -10px;
    top: -10px;
}

.checkBox input[type=checkbox] {
    position: absolute;
    left: 50px;
    visibility: hidden;
}

.transition {
    transition: 500ms ease;
}