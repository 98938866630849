/* PlusContainer.css */

.plus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 88vw; /* Adjust the width as needed */
  height: 70vh; /* Adjust the height as needed */
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.plus-icons {
  display: flex;
  /* gap: 10px; */
}

.plus-icon {
  font-size: 24px;
  background-color: #007bff;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.textarea {
  width: 100%; /* Adjust width as needed */
  height: 100%; /* Adjust height as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical; /* Allow vertical resizing */
  overflow-y: auto; /* Add scrollbar when content exceeds textarea height */
}

.full-size {
  width: 100%;
  height: 100%;
}

.plus-icon:hover {
  background-color: #0056b3;
}

.container-text {
  margin-top: 10px;
  font-size: 20px;
}
